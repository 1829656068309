export default{
    data: () => ({
        balance: 0,
        sms_queue: 0,
        rate_per_sms: 0,
        smsDetails: {},
        sms_stats: {},
        fetchingStats:false,
    }),
    methods:{
        getBalance() {
            this.fetchingStats = true;
            this.$rest
              .get("/api/sms/dashboard")
              .then((res) => {
                this.balance = res.data.balance;
                this.sms_queue = res.data.smsqueue;
                this.rate_per_sms = res.data.rate_per_sms;
                this.sms_stats = res.data;
              })
              .finally(() => {
                this.fetchingStats = false;
              });
          },
    }
}