<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12 v-if="!reuseComponentFlag">
        <v-card outlined class="primary--border">
          <v-card-title style="border-bottom: 1px solid #ccc" class="title">
            SMS Templates
            <v-spacer></v-spacer>
            <add-button
              permission="grade-create"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
              >New Template</add-button
            >
          </v-card-title>
          <br />
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            sort-by="id"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ ++index }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.message }}</td>
                <td class="text-right">
                  <edit-button
                    permission="template-create"
                    @agree="
                      form.edit(item),
                        (updateFlag = true),
                        comKey++,
                        filterDefaultPlaceHolder(item.name)
                    "
                  />
                  <delete-button
                    v-if="item.companyId !== null"
                    permission="template-create"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="900px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-5">
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-text-field
                outlined
                dense
                autocomplete="off"
                label="Name*"
                required
                class="pa-0"
                v-model="form.name"
                name="name"
                :error-messages="form.errors.get('name')"
                :rules="[(v) => !!v || 'Name Field is required']"
                :disabled="disableTitle"
              />
              <div v-if="Object.keys(sms_stats).length > 0 && !reuseComponentFlag">
                <v-sms-input-field
                  v-model="form.message"
                  :hide-balance="true"
                  :show-alert="false"
                  :balance-stats="sms_stats"
                  :default-placeholder="
                    updateFlag == true ? defaultPlaceholder : []
                  "
                  :default-sms="form.message"
                  :key="comKey"
                ></v-sms-input-field>
              </div>
              <div v-if=reuseComponentFlag>
                <v-sms-input-field
                  :key="comKey"
                  v-model="form.message"
                  :hide-balance="true"  
                  :show-alert="false"
                  :balance-stats="sms_stats"
                  :default-sms="form.message"
                  :default-placeholder="defaultPlaceholder"
                  :emitNewValue="true"
                  v-on:newFormValue=newFormValue
                >

                </v-sms-input-field>
              </div>
              <v-sms-stats
                :loading="fetchingStats"
                :smsQueue="sms_queue"
                :balance="balance"
                :birthdayUse="true"
                :ratePerSMS="rate_per_sms"
              ></v-sms-stats>
              <!-- <v-textarea
                outlined
                dense
                autocomplete="off"
                label="Message*"
                required
                class="pa-0"
                v-model="form.message"
                name="rank"
                :rows="4"
                :error-messages="form.errors.get('message')"
                :rules="[(v) => !!v || 'Message Field is required']"
              /> -->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="
                (form.dialog = false),
                  form.reset(),
                  (updateFlag = false),
                  comKey++,
                  resetAll(),
                  disableTitle=false;
              "
              >Close</v-btn
            >
            <v-btn color="success" text @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import SmsBalance from "@/library/SmsBalance";
import { slugifyText } from "@/library/helpers";
export default {
  props:{
    reuseComponentFlag:{
      default:false,
    },
    settingFor:{
      default:"",
    },
    billingMessage:{
      default:"",
    },
  },
  mixins: [Mixins, SmsBalance],
  data: () => ({

    comKey: 1,
    updateFlag: false,
    valid: true,
    lazy: false,
    isLoading: false,
    co_ordinator: [],
    model: null,
    entries: [],
    form: new Form(
      {
        name: "",
        message: "",
      },
      "/api/sms/template"
    ),
    disableTitle:false,
    search: "",
    searchCo: "",
    teacherList: [],
    pagination: {},
    headers: [
      { text: "#", align: "left", value: "id", width: 10, sortable: false },
      { text: "Title", align: "left", value: "name", sortable: false },
      { text: "Message", align: "left", value: "message", sortable: false },
      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
    defaultPlaceholder: [
      {
        text: "Student Name",
        placeholder: "[[student_name]]",
      },
      {
        text: "Father Name",
        placeholder: "[[father_name]]",
      },
      {
        text: "Mother Name",
        placeholder: "[[mother_name]]",
      },
      {
        text: "District",
        placeholder: "[[district]]",
      },
      {
        text: "Address",
        placeholder: "[[address]]",
      },
      {
        text: "Ward No",
        placeholder: "[[ward_no]]",
      },
      {
        text: "From Date",
        placeholder: "[[from_date]]",
      },
      {
        text: "To Date",
        placeholder: "[[to_date]]",
      },
      {
        text: "Grade",
        placeholder: "[[grade]]",
      },
      {
        text: "GPA",
        placeholder: "[[gpa]]",
      },
      {
        text: "Exam Date BS",
        placeholder: "[[exam_date_BS]]",
      },
      {
        text: "Exam Date AD",
        placeholder: "[[exam_date_AD]]",
      },
      {
        text: "Date Of Birth(BS)",
        placeholder: "[[date_of_birth_BS]]",
      },
      {
        text: "Date Of Birth(AD)",
        placeholder: "[[date_of_birth_AD]]",
      },
      {
        text: "Character",
        placeholder: "[[character]]",
      },
    ],
    feePaymentPlaceHolder:[
      {
        text: "Guardian Name",
        placeholder: "[[GUARDIAN_NAME]]",
      },
      {
        text: "Student Name",
        placeholder: "[[STUDENT_NAME]]",
      },
      {
        text: "Amount",
        placeholder: "[[AMOUNT]]",
      },
      {
        text: "Via",
        placeholder: "[[VIA]]",
      },
      {
        text: "Reciept No.",
        placeholder: "[[RECEIPT_NO]]",
      },
      {
        text: "Remaining Dues",
        placeholder: "[[REMAINING_DUES]]",
      },
      {
        text: "Payment Date TIme",
        placeholder: "[[PAYMENT_DATE_TIME]]",
      },
      {
        text: "School Name",
        placeholder: "[[SCHOOL_NAME]]",
      },
    ],
  }),
  mounted() {
    this.getBalance();
    if(this.reuseComponentFlag){
      this.get();
    }
  },
  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
   
  },
  methods: {
    newFormValue(v){
      this.form.message = v;
    },
    setData(){
      let filter_data = "";
      if(this.settingFor === 'billing'){
        this.disableTitle = true;
        this.form.name = "FEE_PAYMENT_MSG";
        this.form.message = this.billingMessage;
        this.filterDefaultPlaceHolder('FEE_PAYMENT_MSG');

      //   filter_data = this.form.items.data.filter((a)=>{if(a.title=="FEE_PAYMENT_MSG" && a.is_allowed === '0'){return a}});
      // }else{
      //   filter_data = this.form.items.data.filter((a)=>{if(a.title=="BIRTHDAY_MSG" && a.is_allowed === '0'){return a}});

      }
      if(this.settingFor === 'birthday'){
        filter_data = this.form.items.data.filter((a)=>{if(a.name=="BIRTHDAY_MSG" && a.is_allowed === '0'){return a}});
        filter_data = filter_data[0]
        this.filterDefaultPlaceHolder(filter_data.name);
        this.form.name = filter_data.name;
        this.form.message = filter_data.message;
        this.form.id = filter_data.id;
      }
    },
    filterDefaultPlaceHolder(templateName) {
      const name = slugifyText(templateName);
      if (name == "birthday" || name =="birthday_msg") {
        this.disableTitle=true;
        this.defaultPlaceholder = [
          {
            text: "Student Name",
            placeholder: "[[student_name]]",
          },
          {
            text: "Grade",
            placeholder: "[[grade]]",
          },
          {
            text: "Section",
            placeholder: "[[section]]",
          },
          {
            text: "Father Name",
            placeholder: "[[father_name]]",
          },
          {
            text: "Mother Name",
            placeholder: "[[mother_name]]",
          },
          {
            text: "Date Of Birth(BS)",
            placeholder: "[[date_of_birth_BS]]",
          },
          {
            text: "Date Of Birth(AD)",
            placeholder: "[[date_of_birth_AD]]",
          },
          {
            text: "Years Old",
            placeholder: "[[years_old]]",
          },
        ];
      } else if (name == "fee-payment-success") {
        this.disableTitle=true;
        this.defaultPlaceholder = [
          {
            text: "Student Name",
            placeholder: "[[student_name]]",
          },
          {
            text: "Grade",
            placeholder: "[[grade]]",
          },
          {
            text: "Section",
            placeholder: "[[section]]",
          },
          {
            text: "Accountant",
            placeholder: "[[accountant_name]]",
          },
          {
            text: "Amount",
            placeholder: "[[amount]]",
          },
          {
            text: "Due Amount",
            placeholder: "[[due_amount]]",
          },
          {
            text: "Father Name",
            placeholder: "[[father_name]]",
          },
          {
            text: "Mother Name",
            placeholder: "[[mother_name]]",
          },
          {
            text: "Payment Via",
            placeholder: "[[payment_via]]",
          },
        ];
      } else if (name == "pending-dues-alert") {
        this.disableTitle=true;
        this.defaultPlaceholder = [
          {
            text: "Student Name",
            placeholder: "[[student_name]]",
          },
          {
            text: "Father Name",
            placeholder: "[[father_name]]",
          },
          {
            text: "Mother Name",
            placeholder: "[[mother_name]]",
          },
          {
            text: "Guardian Mobile",
            placeholder: "[[guardian_mobile]]",
          },
          {
            text: "Due Amount",
            placeholder: "[[due_amount]]",
          },
        ];
      } else if(name == "fee_payment_msg"){
        this.disableTitle=true;
        this.defaultPlaceholder=[
          {
            text: "Guardian Name",
            placeholder: "[[GUARDIAN_NAME]]",
          },
          {
            text: "Student Name",
            placeholder: "[[STUDENT_NAME]]",
          },
          {
            text: "Amount",
            placeholder: "[[AMOUNT]]",
          },
          {
            text: "Via",
            placeholder: "[[VIA]]",
          },
          {
            text: "Reciept No.",
            placeholder: "[[RECEIPT_NO]]",
          },
          {
            text: "Remaining Dues",
            placeholder: "[[REMAINING_DUES]]",
          },
          {
            text: "Payment Date TIme",
            placeholder: "[[PAYMENT_DATE_TIME]]",
          },
          {
            text: "School Name",
            placeholder: "[[SCHOOL_NAME]]",
          },
        ]
      }else {
        this.defaultPlaceholder = [];
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        if(this.reuseComponentFlag){
          let $this = this;
          $this.form.dialog = true
          $this.setData();
        }else{
          this.form.dialog = false
        }
      });
    },

    save() {

      this.form.batch_id = this.batch.id;
      if (this.form.coordinator_id && Object.keys(this.form.coordinator_id))
        this.form.coordinator_id = this.form.coordinator_id.id;
      if(this.settingFor === 'billing') return this.$emit("billingMsg",this.form.message)
      this.form.store().then();
      this.disableTitle=false;
      if(this.reuseComponentFlag){
        this.resetAll();
      }
    },
    resetAll(){
      this.$emit("reset");
    }
  },
  
};
</script>
<style lang="scss" scoped></style>
